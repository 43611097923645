


import req from './https'


//  定義街口
export const apiGetStaffName = (params) => req('get', '/Questionnire/GetStaffName', params)


//  取得足跡
export const apiGetFootprintAll = (params) => req('get', '/Questionnire/GetFootprintAll', params)


//  取得外部足跡
export const apiGetExternalFootprintAll = (params) => req('get', '/ExternalQnr/GetFootprintAll', params)
