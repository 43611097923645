


import req from './https'

//  取得快篩圖檔
export const apiGetRapidTestPicBase64 = (params) => req('get', '/FileUpload/GetRapidTestPicBase64', params)

//  送出表單
export const apiAddRestaurantCnr = (params) => req('post', '/Questionnire/AddRestaurantCnr', params)
export const apiAddSmokingZoneCnr = (params) => req('post', '/Questionnire/AddSmokingZoneCnr', params)
export const apiAddPersonalHealthQuestionnaire = (params) => req('post', '/Questionnire/AddPersonalHealthQuestionnaire', params)
export const apiAddExternalHealthQuestionnaire = (params) => req('post', '/ExternalQnr/AddExternalHealthQuestionnaire', params)


//  取得問卷
export const apiGetPersonalHealthQuestionnaireList = (params) => req('post', '/Questionnire/GetPersonalHealthQuestionnaireListRange', params)
export const apiGetRestaurantCnrList = (params) => req('post', '/Questionnire/GetRestaurantCnrList', params)
export const apiGetSmokingZoneCnrList = (params) => req('post', '/Questionnire/GetSmokingZoneCnrList', params)
export const apiGetExternalHealthQuestionnaireList = (params) => req('post', '/ExternalQnr/GetExternalHealthQuestionnaireList', params)


//  取得單一問卷
export const apiGetPersonalHealthQuestionnaire = (params) => req('get', '/Questionnire/GetPersonalHealthQuestionnaire', params)
export const apiGetExternalHealthQuestionnaire = (params) => req('get', '/ExternalQnr/GetExternalHealthQuestionnaire', params)



//  匯出問卷
export const apiExportPersonalHealthQuestionnaire = (params) => req('post', '/Questionnire/ExportPersonalHealthQuestionnaireRange', params, { responseType: "blob" })
export const apiExportRestaurantCnr = (params) => req('post', '/Questionnire/ExportRestaurantCnr', params, { responseType: "blob" })
export const apiExportSmokingZoneCnr = (params) => req('post', '/Questionnire/ExportSmokingZoneCnr', params, { responseType: "blob" })
export const apiExportExternalHealthQuestionnaire = (params) => req('post', '/ExternalQnr/ExportExternalHealthQuestionnaire', params, { responseType: "blob" })



//  疫調問卷題目
export const apiGetPHSurveyItemsAll = (params) => req('get', '/Questionnire/GetPHSurveyItemsAll', params)
export const apiGetExternalPHSurveyItemsAll = (params) => req('get', '/ExternalQnr/GetPHSurveyItemsAll', params)

//  確認疫調問卷是否開放
export const apiCheckPersonalHealthQnrEnabled = (params) => req('get', '/Questionnire/CheckPersonalHealthQnrEnabled', params)

export const apiCheckExternalHealthQnrEnabled = (params) => req('get', '/ExternalQnr/CheckExternalHealthQnrEnabled', params)

//  疫調問卷排除名單
export const apiGetStaffPages = (params) => req('post', '/Questionnire/GetStaffPages', params)
export const apiChangeStaffExcluded = (params) => req('post', '/Questionnire/ChangeStaffExcluded', params)    


